import React, { useCallback, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { OKTA_STEP } from '../utils/Constants';
import DeskeraInfo from '../components/DeskeraInfo';
import OktaLogin from '../components/okta/OktaLogin';
import OktaLoginSuccess from '../components/okta/OktaLoginSuccess';
import { showToast, TOAST_TYPE } from '../components/common/Toast';
import SignupService from '../services/SignupService';
import OktaLoginEmbedded from '../components/okta/OktaLoginEmbedded';

const OktaLoginPage = ({ isEmbedded }) => {
  const { authState } = useOktaAuth();
  const [currentStep, setCurrentStep] = useState(isEmbedded ? OKTA_STEP.OKTA_LOGIN_EMBEDDED : OKTA_STEP.OKTA_LOGIN);

  const loginToDeskera = useCallback(() => {
    const payload = {
      ...authState.accessToken,
      ...authState.idToken
    };

    SignupService.loginToDeskera(payload).then(
      (response) => {
        setCurrentStep(OKTA_STEP.OKTA_LOGIN_SUCCESS);
      },
      (error) => {
        showToast('Internal server error. Please try again later.', TOAST_TYPE.FAILURE);
      }
    );
  }, [authState]);

  useEffect(() => {
    if (authState?.isAuthenticated) {
      loginToDeskera();
    }
  }, [authState, loginToDeskera]);

  return (
    <div className="signup-page">
      <main className="signup-container">
        <div className="signup-content">
          <div className="left-column">
            <DeskeraInfo />
          </div>
          <div className="right-column">
            {currentStep === OKTA_STEP.OKTA_LOGIN && <OktaLogin />}
            {currentStep === OKTA_STEP.OKTA_LOGIN_EMBEDDED && <OktaLoginEmbedded />}
            {currentStep === OKTA_STEP.OKTA_LOGIN_SUCCESS && <OktaLoginSuccess />}
          </div>
        </div>
      </main>
    </div>
  );
};

export default OktaLoginPage;
