import React, { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Spinner from '../common/Spinner';
import oktaLogo from '../../assets/okta-logo.svg';

const OktaLogin = () => {
  const { oktaAuth } = useOktaAuth();

  const [showSpinner, setShowSpinner] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleOktaLogin = async () => {
    setShowSpinner(true);
    await oktaAuth.signInWithRedirect();
  };

  return (
    <div className="integration-login-step">
      <div>
        <h1>
          <img src={oktaLogo} className="integration-logo" alt="okta logo" />
        </h1>
        <h3>Login into Deskera with Okta integration</h3>
      </div>
      <div>
        <button
          className="btn-primary"
          disabled={disableSubmit}
          onClick={() => {
            setDisableSubmit(true);
            handleOktaLogin();
          }}
        >
          Login
          {showSpinner && <Spinner />}
        </button>
      </div>
    </div>
  );
};

export default OktaLogin;
