import { getLabel } from '../../utils/CommonUtils';

const Select = ({ id, label, className, value, options, required, invalid, errorMsg, onChange }) => {
  return (
    <>
      <label className={invalid ? 'invalid-label' : ''} htmlFor={id}>
        {getLabel(label, required)}
      </label>
      <select
        id={id}
        className={`${invalid && 'invalid-input'} ${className}`}
        value={value}
        required={required}
        onChange={(e) => {
          e.preventDefault();
          onChange(e.target.value);
        }}
      >
        {options.map((option) => (
          <option value={option.id} selected={option.isDefault} disabled={option.isDefault}>
            {option.name}
          </option>
        ))}
      </select>
      {invalid && errorMsg && <div className="error-msg">{errorMsg}</div>}
    </>
  );
};

export default Select;
