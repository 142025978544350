import '../../styles/toast.css';

export const TOAST_DIRECTION = {
  TOP: 'top',
  BOTTOM: 'bottom'
};

export const TOAST_TYPE = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  FAILURE: 'failure',
  WARNING: 'warning'
};

export function showToast(message, type = TOAST_TYPE.DEFAULT, direction = TOAST_DIRECTION.TOP) {
  let div = document.createElement('div');

  let type_class = 'toast-' + type;
  let type_direction = 'toast-' + direction;
  div.className = 'toast ' + type_class + ' ' + type_direction;

  div.innerHTML = message;

  let parent = document.getElementById('root');
  parent.appendChild(div);

  setTimeout(() => {
    parent.removeChild(div);
  }, 2000);
}
