import Axios from '../utils/Axios';
import ApiConstants from '../utils/ApiConstants';
import EnvConstants from '../utils/EnvConstants';

export default class SignupService {
  static validateEmailAndContact(params) {
    return Axios.get(ApiConstants.URL.IS_USER_EXIST, { params: params });
  }

  static signup(payload) {
    return Axios.post(ApiConstants.URL.SIGN_UP, payload);
  }

  static postSignupData(payload) {
    return Axios.post(EnvConstants.SHEETDB_URL, JSON.stringify(payload), {
      withCredentials: false,
      headers: {
        'access-control-allow-origin': '*',
        Authorization: `Bearer ${EnvConstants.SHEETDB_AUTH_TOKEN}`,
        'Content-Type': 'application/json'
      }
    });
  }

  static loginToDeskera(payload) {
    console.log('payload', payload);
    return new Promise((resolve, reject) => {
      resolve(true);
    });

    // return Axios.post(ApiConstants.URL.OKTA_LOGIN_DESKERA, payload);
  }
}
