import React from 'react';
import '../../styles/spinner.css';

const Spinner = () => {
  return (
    <div class="loader"></div>
  );
};

export default Spinner;
