import React, { useState } from 'react';
import Spinner from '../common/Spinner';
import workdayLogo from '../../assets/workday-logo.svg';
import EnvConstants from '../../utils/EnvConstants';

const WorkdayLogin = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleWorkdayLogin = async () => {
    setShowSpinner(true);
    const URL = `${EnvConstants.WORKDAY_ENDPOINT}?response_type=code&client_id=${EnvConstants.WORKDAY_CLIENT_ID}&redirect=n`;
    window.open(URL, '_self');
  };

  return (
    <div className="integration-login-step">
      <div>
        <h1>
          <img src={workdayLogo} className="integration-logo" alt="workday logo" />
        </h1>
        <h3>Login into Deskera with Workday integration</h3>
      </div>
      <div>
        <button
          className="btn-primary"
          disabled={disableSubmit}
          onClick={() => {
            setDisableSubmit(true);
            handleWorkdayLogin();
          }}
        >
          Login
          {showSpinner && <Spinner />}
        </button>
      </div>
    </div>
  );
};

export default WorkdayLogin;
