import EnvConstants from './EnvConstants';

export default class ApiConstants {
  static URL = {
    BASE: EnvConstants.BASE_URL,
    IS_USER_EXIST: 'v1/iam/auth/user',
    SIGN_UP: 'v1/iam/auth/sign-up/web',
    OKTA_LOGIN_DESKERA: 'v1/iam/auth/user'
  };
}
