export const SIGNUP_STEP = {
  USER_INFO: 'USER_INFO',
  COMPANY_INFO: 'COMPANY_INFO',
  SUCCESS_MSG: 'SUCCESS_MSG'
};

export const OKTA_STEP = {
  OKTA_LOGIN: 'OKTA_LOGIN',
  OKTA_LOGIN_EMBEDDED: 'OKTA_LOGIN_EMBEDDED',
  OKTA_LOGIN_SUCCESS: 'OKTA_LOGIN_SUCCESS'
}

export const WORKDAY_STEP = {
  WORKDAY_LOGIN: 'WORKDAY_LOGIN',
  WORKDAY_LOGIN_SUCCESS: 'WORKDAY_LOGIN_SUCCESS'
}

export const REGEX = {
  EMAIL:
    /^(?!\.)(?!.*?\.\.)(([A-Za-z0-9_\-.+]){1,64})(?!\.)@(?!-)(((\[?)(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})(\]?)))|((((([a-zA-Z0-9-]{1,61})\.){1,4}(?!web)[a-zA-Z]{1,}))))$/,
  PASSWORD: /^(?! ).(?:\s)*.{6,71}[^(?! )]$/
};

export const COMPANY_SIZE_OPTIONS = [
  { id: '', name: 'Select company size', isDefault: true },
  { id: '1-10', name: '1-10 employees' },
  { id: '11-50', name: '11-50 employees' },
  { id: '51-200', name: '51-200 employees' },
  { id: '201-1000', name: '201-1000 employees' },
  { id: '1000+', name: '1000+ employees' }
];

export const ROLE_OPTIONS = [
  { id: '', name: 'Select your role', isDefault: true },
  { id: 'owner', name: 'Business Owner' },
  { id: 'executive', name: 'Executive' },
  { id: 'manager', name: 'Manager' },
  { id: 'employee', name: 'Employee' },
  { id: 'other', name: 'Other' }
];
