import React from 'react';

const DeskeraInfo = () => {
  return (
    <div>
      <div className="logo">Deskera</div>
      <h1>Unlock 360&deg; business visibility</h1>
      <p>Join savvy enterprises worldwide harnessing real-time insights through Deskera ERP.</p>
      <div className="feature-checklist">
        <ul>
          <li>
            <input className="checkmark" type="checkbox" defaultChecked />
            <div>AI-powered interface, zero learning curve.</div>
          </li>
          <li>
            <input className="checkmark" type="checkbox" defaultChecked />
            <div>Modular pricing, from startups to corporations.</div>
          </li>
          <li>
            <input className="checkmark" type="checkbox" defaultChecked />
            <div>15-day full-access sandbox environment.</div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DeskeraInfo;
