import axios from 'axios';
import ApiConstants from './ApiConstants';

const Axios = axios.create({
  withCredentials: true,
  baseURL: ApiConstants.URL.BASE
});

const requestInterceptSuccess = (config) => {
  return config;
};

const requestInterceptError = (error) => {
  return Promise.reject(error);
};

const responseInterceptSuccess = (response) => {
  return response.data;
};

const responseInterceptError = async (error) => {
  return Promise.reject(error.response);
};

Axios.interceptors.request.use(
  (response) => requestInterceptSuccess(response),
  (error) => requestInterceptError(error)
);

Axios.interceptors.response.use(
  (response) => responseInterceptSuccess(response),
  (error) => responseInterceptError(error)
);

export default Axios;
