import React, { useCallback, useState } from 'react';
import { WORKDAY_STEP } from '../utils/Constants';
import DeskeraInfo from '../components/DeskeraInfo';
import WorkdayLogin from '../components/workday/WorkdayLogin';
import WorkdayLoginSuccess from '../components/workday/WorkdayLoginSuccess';
import { showToast, TOAST_TYPE } from '../components/common/Toast';
import SignupService from '../services/SignupService';

const WorkdayLoginPage = () => {
  const [currentStep, setCurrentStep] = useState(WORKDAY_STEP.WORKDAY_LOGIN);

  const loginToDeskera = useCallback(() => {
    const payload = {};

    SignupService.loginToDeskera(payload).then(
      (response) => {
        setCurrentStep(WORKDAY_STEP.WORKDAY_LOGIN_SUCCESS);
      },
      (error) => {
        showToast('Internal server error. Please try again later.', TOAST_TYPE.FAILURE);
      }
    );
  }, []);

  const onLogin = () => {
    loginToDeskera();
  }

  return (
    <div className="signup-page">
      <main className="signup-container">
        <div className="signup-content">
          <div className="left-column">
            <DeskeraInfo />
          </div>
          <div className="right-column">
            {currentStep === WORKDAY_STEP.WORKDAY_LOGIN && <WorkdayLogin onLogin={onLogin} />}
            {currentStep === WORKDAY_STEP.WORKDAY_LOGIN_SUCCESS && <WorkdayLoginSuccess />}
          </div>
        </div>
      </main>
    </div>
  );
};

export default WorkdayLoginPage;
