import React, { useState } from 'react';
import { COMPANY_SIZE_OPTIONS, ROLE_OPTIONS, SIGNUP_STEP } from '../utils/Constants';
import Input from './common/Input';
import Select from './common/Select';
import Spinner from './common/Spinner';

const CompanyInfo = ({ showSpinner, disableSubmit, onSubmit }) => {
  const [organizationName, setOrganizationName] = useState({
    id: 'organizationName',
    type: 'text',
    label: "What's your company name?",
    value: '',
    required: true,
    invalid: false
  });

  const [companySize, setCompanySize] = useState({
    id: 'companySize',
    type: 'select',
    label: 'How big is your company?',
    value: '',
    options: COMPANY_SIZE_OPTIONS,
    required: true,
    invalid: false
  });

  const [role, setRole] = useState({
    id: 'role',
    type: 'select',
    label: "What's your role?",
    value: '',
    options: ROLE_OPTIONS,
    required: true,
    invalid: false
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      step: SIGNUP_STEP.COMPANY_INFO,
      organizationName: organizationName.value,
      companySize: companySize.value,
      role: role.value
    });
  };

  return (
    <div className="role-and-company-step">
      <h2>Tell us about your company</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <Input
            id={organizationName.id}
            type={organizationName.type}
            label={organizationName.label}
            value={organizationName.value}
            required={organizationName.required}
            invalid={organizationName.invalid}
            errorMsg={organizationName.errorMsg}
            onChange={(value) => {
              setOrganizationName((prevState) => {
                return {
                  ...prevState,
                  errorMsg: null,
                  value: value
                };
              });
            }}
          />
        </div>
        <div className="form-group">
          <Select
            id={companySize.id}
            label={companySize.label}
            value={companySize.value}
            options={companySize.options}
            required={companySize.required}
            invalid={companySize.invalid}
            errorMsg={companySize.errorMsg}
            onChange={(value) => {
              setCompanySize((prevState) => {
                return {
                  ...prevState,
                  errorMsg: null,
                  value: value
                };
              });
            }}
          />
        </div>
        <div className="form-group">
          <Select
            id={role.id}
            label={role.label}
            value={role.value}
            options={role.options}
            required={role.required}
            invalid={role.invalid}
            errorMsg={role.errorMsg}
            onChange={(value) => {
              setRole((prevState) => {
                return {
                  ...prevState,
                  errorMsg: null,
                  value: value
                };
              });
            }}
          />
        </div>
        <div>
          <button type="submit" className="btn-primary" disabled={disableSubmit}>
            Create Account
            {showSpinner && <Spinner />}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CompanyInfo;
