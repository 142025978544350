export default class EnvConstants {
  static APP_URL = process.env.REACT_APP_APP_URL;
  static BASE_URL = process.env.REACT_APP_BASE_URL;
  static LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
  static REDIRECT_URL = process.env.REACT_APP_REDIRECT_URL;
  static SHEETDB_URL = process.env.REACT_APP_SHEETDB_URL;

  static GTM_ID = process.env.REACT_APP_GTM_ID;
  static SHEETDB_AUTH_TOKEN = process.env.REACT_APP_SHEETDB_AUTH_TOKEN;
  static DENIED_MAIL_DOMAIL = process.env.REACT_APP_DENIED_MAIL_DOMAIL.split(',');
  static REDIRECT_TIMER = process.env.REACT_APP_REDIRECT_TIMER;

  static OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER;
  static OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;

  static WORKDAY_ENDPOINT = process.env.REACT_APP_WORKDAY_ENDPOINT;
  static WORKDAY_CLIENT_ID = process.env.REACT_APP_WORKDAY_CLIENT_ID;
}
