import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import SignupPage from './pages/SignupPage';
import TagManager from 'react-gtm-module';
import EnvConstants from './utils/EnvConstants';
import OktaLoginPage from './pages/OktaLoginPage';
import WorkdayLoginPage from './pages/WorkdayLoginPage';

const oktaAuth = new OktaAuth({
  issuer: `https://${EnvConstants.OKTA_ISSUER}.com/oauth2/default`,
  clientId: EnvConstants.OKTA_CLIENT_ID,
  redirectUri: `${EnvConstants.APP_URL}/login/okta/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  disableHttpsCheck: false
});

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = () => {
    navigate(toRelativeUrl('/login/okta', window.location.origin));
  };

  useEffect(() => {
    // Initialize GTM
    const tagManagerArgs = {
      gtmId: EnvConstants.GTM_ID
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/" element={<SignupPage />} />
        <Route path="/login/okta" element={<OktaLoginPage isEmbedded={false} />} />
        <Route path="/login/okta/embedded" element={<OktaLoginPage isEmbedded={true} />} />
        <Route path="/login/okta/callback" element={<LoginCallback />} />
        <Route path="/login/workday" element={<WorkdayLoginPage />} />
      </Routes>
    </Security>
  );
}

export default App;
