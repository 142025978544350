import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
import '../../styles/okta-widget.css';

import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import EnvConstants from '../../utils/EnvConstants';
import deskeraLogo from '../../assets/deskera-logo.png';

const OktaSignInWidget = ({ onSuccess, onError }) => {
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const widget = new OktaSignIn({
      baseUrl: `${EnvConstants.OKTA_ISSUER}.com`,
      issuer: `https://${EnvConstants.OKTA_ISSUER}.com/oauth2/default`,
      clientId: EnvConstants.OKTA_CLIENT_ID,
      redirectUri: `${EnvConstants.APP_URL}/login/okta/callback`,
      useClassicEngine: true,
      authParams: {
        pkce: true
      },
      logo: deskeraLogo,
      colors: {
        brand: "#000000"
      }
    });
    widget.showSignInToGetTokens({ el: widgetRef.current }).then(onSuccess).catch(onError);

    return () => widget.remove();
  }, [onSuccess, onError]);

  return <div ref={widgetRef} />;
};

export default OktaSignInWidget;
