import React, { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Spinner from '../common/Spinner';
import oktaLogo from '../../assets/okta-logo.svg';
import { showToast, TOAST_TYPE } from '../common/Toast';
import OktaSignInWidget from './OktaSignInWidget';

const OktaLoginEmbedded = () => {
  const { oktaAuth } = useOktaAuth();

  const [showEmbeddedWidget, setShowEmbeddedWidget] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleOktaLogin = async () => {
    setDisableSubmit(true);
    setShowSpinner(true);
    setShowEmbeddedWidget(true);
  };

  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    showToast('Internal server error. Please try again later.', TOAST_TYPE.FAILURE);
    setDisableSubmit(false);
    setShowSpinner(false);
  };

  const renderEmbeddedWidgetView = () => {
    return <OktaSignInWidget onSuccess={onSuccess} onError={onError} />;
  };

  const renderLoginEmbeddedView = () => {
    return (
      <div className="integration-login-step">
        <div>
          <h1>
            <img src={oktaLogo} className="integration-logo" alt="okta logo" />
          </h1>
          <h3>Login into Deskera with Okta integration</h3>
        </div>
        <div>
          <button
            className="btn-primary"
            disabled={disableSubmit}
            onClick={() => {
              handleOktaLogin();
            }}
          >
            Login
            {showSpinner && <Spinner />}
          </button>
        </div>
      </div>
    );
  };

  return showEmbeddedWidget ? renderEmbeddedWidgetView() : renderLoginEmbeddedView();
};

export default OktaLoginEmbedded;
