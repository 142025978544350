import React, { useCallback, useState } from 'react';
import EnvConstants from '../utils/EnvConstants';
import Spinner from './common/Spinner';

const SuccessMsg = () => {
  const [redirectTimer, setRedirectTimer] = useState(EnvConstants.REDIRECT_TIMER);
  const [showSpinner, setShowSpinner] = useState(false);

  const naviageToRedirectURL = useCallback(() => {
    window.open(EnvConstants.REDIRECT_URL, '_self');
  }, []);

  useState(() => {
    const interval = setInterval(() => {
      setRedirectTimer((prevState) => {
        const newState = prevState - 1;
        if (newState <= 0) {
          naviageToRedirectURL();
          return 0;
        }

        return newState;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [naviageToRedirectURL]);

  return (
    <div className="success-step">
      <h2>Welcome to Deskera!</h2>
      <p>Your account has been successfully created.</p>
      <p>Check your email for further instructions on getting started.</p>
      <div className="redirecting">
        You will be redirected to Deskera system in... {redirectTimer}
        <button
          className="btn-primary"
          onClick={() => {
            setShowSpinner(true);
            naviageToRedirectURL();
          }}
        >
          Take me there now
          {showSpinner && <Spinner />}
        </button>
      </div>
    </div>
  );
};

export default SuccessMsg;
